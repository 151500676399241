import React from "react";
import "../../App.css";
import Cards from "../Cards";
import HeroSection from "../HeroSection";
import {Helmet} from "react-helmet"
import { useTranslation } from "react-i18next";
import ReferencesSlider from "../slider/ReferencesSlider";

function Home() {
  const {t}=useTranslation(['home'])
  return (
    <>
    <Helmet>
      <title>{t("homeTitle")}</title>
    
      <meta itemProp="name" content="Ana Sayfa CSK Yazılım"/>
      <meta itemProp="description" content="CSK Yazılım Hizmetleri ve Bilişim Teknolojolieri Ana sayfa"/>
      <meta name="description" content="CSK Yazılım Hizmetleri ve Bilişim Teknolojolieri Ana sayfa"/>
      <meta property="og:url" content="http://www.cskyazilim.com/"/>
{/* <meta property="og:title" content="Ana Sayfa"/> */}
<meta property="og:type" content="website"/>
<meta property="og:site_name" content="CSK Yazılım Hizmetleri ve Bilişim Teknolojileri A.Ş."/>
    </Helmet>
      <HeroSection />
      <ReferencesSlider/>
      <Cards />
    </>
  );
}

export default Home;
