import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import '../pagescss/Services.css'


function Card({title,body,link,ıconp,}) {
  const {t}=useTranslation(['servicesCard'])
  return (
        <div className="cardd"> 
         <i className={ıconp}></i> 
        <Link to={link} className=" text-decoration-none"> <h3 className='cardd-tittlee'>{t(title)}</h3></Link>
          <p className='cardd-bodyy'>{t(body)}</p>
       </div> 
   
  )
}

export default Card