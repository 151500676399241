
export const ReferencesPage = [
    {
      "url": "https://unitedpayment.com/",
      "img_url": "https://unitedpayment.com/_assets/images/logo.svg?v=tWSPBOyrN2Tp7RFr12dyastApW4WErdbDRMROmjugwk",
      "alt": "united payment",
      "bg_color":"#ffff"
    },
    {
      "url": "https://www.birlesikodeme.com/",
      "img_url": "/images/references/logo-tr.png",
      "alt": "birleşik ödeme",
      "bg_color":"#ffff"
    },
    {
        "url": "https://innovance.com.tr/",
        "img_url": "https://innovance.com.tr/wp-content/uploads/2021/08/logo.svg",
        "alt": "innovance",
        "bg_color":"#452170"
      },
      {
        "url": "https://tippay.app/",
        "img_url": "https://tippay.app/wp-content/uploads/2021/09/9-1.png",
        "alt": "tippay",
        "bg_color":"#ffff"
      },
      {
        "url": "https://www.securefuture.com.tr/",
        "img_url": "https://www.securefuture.com.tr/assets/images/logo.webp",
        "alt": "secure future",
        "bg_color":"#000000"
      },
      {
        "url": "https://mtholding.com.tr/",
        "img_url": "https://mtholding.com.tr/images/logo.png",
        "alt": "mt holding",
        "bg_color":"#000000"
      },
      {
        "url": "https://www.smartpackturkiye.com/",
        "img_url": "/images/references/smartpack-logo.png",
        "alt": "smartpack",
        "bg_color":"#ffff"
      },
      {
        "url": "https://www.p3s.com.tr/",
        "img_url": "/images/references/p3s-logo.png",
        "alt": "p3s",
        "bg_color":"#ffff"
      }
  ];
  